import React, { useState, useEffect } from "react";
// import React from 'react'

// function DotLoading() {
//   return (
//     <div>DotLoading</div>
//   )
// }

// export default DotLoading
const DotLoading = ({ contStyle }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 5 ? prevDots + "." : ""));
    }, 350);

    return () => clearInterval(interval);
  }, []);

  return <div style={contStyle && contStyle}>Loading{dots}</div>;
};

export default DotLoading;
