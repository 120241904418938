import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./global.css";
import DotLoading from "./components/SharedUI/DotLoading";
import "react-datepicker/dist/react-datepicker.css";
const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("NODE_ENV:", process.env.NODE_ENV);
console.log("REACT_APP_BASE_URL:", process.env.REACT_APP_BASE_URL);

root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <DotLoading
          contStyle={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "75vh",
          }}
        />
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);
// const token = localStorage.getItem("x-web-auth");
// if (!token) {
//   console.log("Redirecting to login...");
//   window.location.href = `${BASE_URL}login`;
// } else {
//   root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );
// }
